.number {
    width: 3vw;
    height: 3vw;
    background-color: gray;
    color: white;
    margin: 0.8vw;
    grid-column: 1;
    text-align: center;
    float: left;

}
.numberRow {
    justify-content: center;
    width: 14rem;
    width: fit-content;
}
