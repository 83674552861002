@font-face {
    font-family: "icomoon";
    src: url("../assets/fonts/icomoon/icomoon.eot?pvbuug");
    src: url("../assets/fonts/icomoon/icomoon.eot?pvbuug#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/icomoon/icomoon.ttf?pvbuug") format("truetype"),
        url("../assets/fonts/icomoon/icomoon.woff?pvbuug") format("woff"),
        url("../assets/fonts/icomoon/icomoon.svg?pvbuug#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



.icon-advertising:before {
  content: "\e930";
}
.icon-message:before {
  content: "\e931";
}
.icon-play-focus:before {
  content: "\e900";
}
.icon-pause-focus:before {
  content: "\e901";
}
.icon-face-angry:before {
  content: "\e902";
}
.icon-face-sad:before {
  content: "\e903";
}
.icon-face-neutral:before {
  content: "\e904";
}
.icon-face-happy:before {
  content: "\e905";
}
.icon-face-very-happy:before {
  content: "\e906";
}
.icon-unlike:before {
  content: "\e907";
}
.icon-like:before {
  content: "\e908";
}
.icon-adult-movies:before {
  content: "\e909";
}
.icon-alarm:before {
  content: "\e90a";
}
.icon-tv-back-remote:before {
  content: "\e90b";
}
.icon-backward:before {
  content: "\e90c";
}
.icon-backspace:before {
  content: "\e90d";
}
.icon-audio-channels-movies:before {
  content: "\e90e";
}
.icon-crustaceans:before {
  content: "\e90f";
}
.icon-tick-right:before {
  content: "\e910";
}
.icon-channels:before {
  content: "\e911";
}
.icon-celery:before {
  content: "\e912";
}
.icon-subtitles:before {
  content: "\e913";
}
.icon-pause:before {
  content: "\e914";
}
.icon-milk:before {
  content: "\e915";
}
.icon-lupins:before {
  content: "\e916";
}
.icon-home:before {
  content: "\e917";
}
.icon-gluten:before {
  content: "\e918";
}
.icon-start-over:before {
  content: "\e919";
}
.icon-nuts:before {
  content: "\e91a";
}
.icon-mustard:before {
  content: "\e91b";
}
.icon-music:before {
  content: "\e91c";
}
.icon-soy:before {
  content: "\e91d";
}
.icon-cart:before {
  content: "\e91e";
}
.icon-forward:before {
  content: "\e91f";
}
.icon-warning:before {
  content: "\e920";
}
.icon-sesame:before {
  content: "\e921";
}
.icon-multimedia:before {
  content: "\e922";
}
.icon-movies:before {
  content: "\e923";
}
.icon-play:before {
  content: "\e924";
}
.icon-eggs:before {
  content: "\e925";
}
.icon-molluscs:before {
  content: "\e926";
}
.icon-peanuts:before {
  content: "\e927";
}
.icon-sulfur-dioxide-sulphites:before {
  content: "\e928";
}
.icon-fish:before {
  content: "\e929";
}
.icon-message-important:before {
  content: "\e92a";
}
.icon-chevron-right:before {
  content: "\e92b";
}
.icon-chevron-left:before {
  content: "\e92c";
}
.icon-chevron:before {
  content: "\e92d";
}
.icon-chevron-up:before {
  content: "\e92e";
}
.icon-no-color:before {
  content: "\e92f";
}